@tailwind base;
@tailwind components;
@tailwind utilities;

:root{
    background-color: #f9fafb;
    color-scheme: light dark;
}

@media (prefers-color-scheme: light) {
    .element {
      color: black;
      background-color: white;
    }
  }
  
  @media (prefers-color-scheme: dark) {
    .element {
      color: white;
      background-color: black;
    }
  }